"use client";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Image from "next/image";
import Link from "next/link";
import React, { useState, useEffect } from "react";
import dropDownImg from '@/public/images/material-symbols_arrow-drop-down-rounded.svg'
import CustomLink from "./custom-link";

function Headercomponent({lang, navigation}) {
   
  const [fetchedData, setFetchedData] = useState(); // Initialize with null or an appropriate initial value
  const [coeData, setCoeData] = useState();

    // Function to fetch data from an API using async/await
    async function fetchDataFromApi(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        const apiUrl =
        `${process.env.NEXT_PUBLIC_API_URL}/specialities?filters[COE][$eq]=false&locale=${lang?lang:'EN'}`;

            (async () => {
                try {
                  const data = await fetchDataFromApi(apiUrl);
                  setFetchedData(data);
                } catch (error) {
                  console.error("Error:", error);
                }
              })();
          
              const apiUrlforCoe = `${process.env.NEXT_PUBLIC_API_URL}/specialities?filters[COE][$eq]=true&locale=${lang?lang:'EN'}`;
          
              (async () => {
                try {
                  const data = await fetchDataFromApi(apiUrlforCoe);
                  setCoeData(data);
                } catch (error) {
                  console.error("Error:", error);
                }
              })()
            }, 
        []); // Empty dependency array ensures this effect runs only once on mount

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center header-font-color outline-none uppercase">{navigation.specialities}
                    <Image src={dropDownImg} width={24} height={24} alt="dropdown arrow" title="dropdown arrow" />
                </DropdownMenuTrigger>

                <DropdownMenuContent className="rounded-2xl text-gray-600 relative top-2 shadow-lg p-5 m-4 h-\80vh\">
                    <div className="grid gap-2 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 items-start justify-start h-full w-auto">
                        <div className="overflow-y-scroll w-auto col-span-1 ">
                            <div className="fixed bg-white z-10 w-64">
                                <p className="mb-4 text-xl text-primaryBlack font-light">{navigation.coe}</p>
                            </div>
                            <div className="mt-10">
                                {coeData && coeData.data ? (coeData.data.map((e, i) => (
                                    <CustomLink lang={lang} key={i} href={`/centre-of-excellence/${e.attributes.specialityTag.toLowerCase().replaceAll(/\s/g, '-')}`}  className="xl:text-base font-normal md:text-sm speciality_dropdownhomelinks" >
                                        <DropdownMenuItem className="cursor-pointer focus:bg-transparent focus:text-primaryGreyText hover:text-primaryGreyText text-primaryGrey px-0 w-full mb-1">
                                        {e.attributes.specialityName.toUpperCase()}
                                        </DropdownMenuItem>
                                    </CustomLink>
                                ))) : (
                                <p>Loading...</p> // You can display a loading message or any other UI while data is being fetched
                                )}
                            </div>
                        </div>

                        <div className="overflow-y-scroll w-fit xl:col-span-2 col-span-1">
                            <div className="fixed bg-white z-10 w-64">
                                <p className="mb-4 text-xl text-primaryBlack font-light">{navigation.navotherSpecialities}</p>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-4 gap-0 grid-cols-1 items-start justify-start xl:h-auto h-[550px] overflow-scroll">
                                <div className="mt-10">
                                    {fetchedData && fetchedData.data ? (fetchedData.data.slice(0,14).map((e, i) => (
                                        <CustomLink lang={lang} key={i} href={`/speciality/${e.attributes.specialityTag.toLowerCase().replaceAll(/\s/g, '-')}`} className="xl:text-base text-primaryGrey hover:text-primaryGreyText focus:text-primaryGreyText font-normal md:text-sm speciality_dropdownhomelinks" >
                                        <DropdownMenuItem className="cursor-pointer focus:bg-transparent mb-1">
                                        {e.attributes.specialityName.toUpperCase()}
                                        </DropdownMenuItem>
                                    </CustomLink>
                                    ))) : (
                                    <p>Loading...</p> // You can display a loading message or any other UI while data is being fetched
                                    )}
                                </div>
                                <div className="mt-0 xl:mt-10">
                                    {fetchedData && fetchedData.data ? (fetchedData.data.slice(14,fetchedData.data.length).map((e, i) => (
                                        <CustomLink lang={lang} key={i} href={`/speciality/${e.attributes.specialityTag.toLowerCase().replaceAll(/\s/g, '-')}`}  className="xl:text-base text-primaryGrey hover:text-primaryGreyText focus:text-primaryGreyText font-normal md:text-sm speciality_dropdownhomelinks" >
                                        <DropdownMenuItem className="cursor-pointer focus:bg-transparent mb-1">
                                        {e.attributes.specialityName.toUpperCase()}
                                        </DropdownMenuItem>
                                    </CustomLink>
                                    ))) : (
                                    <p>Loading...</p> // You can display a loading message or any other UI while data is being fetched
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
}

export default Headercomponent;
