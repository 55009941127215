'use client'


import Image from 'next/image'
import Link from 'next/link'
import React, { useState, useEffect} from 'react'
import FooterDetails from '../footer/footer-details'
import FooterIcons from '../footer/footer-icons'
import Whatsapp from './whatsappmobile'
import apexlogo from '@/public/images/footer-apex-logo.svg'
import appstore from '@/public/footer-images/app-store.svg'
import playstore from '@/public/footer-images/play-store.svg'
import mail from '@/public/footer-images/material-symbols-mail-rounded.svg'
import informationbox from '@/public/footer-images/information-box.svg'
import emergency from '@/public/footer-images/emergency.svg'
import ambulance from '@/public/footer-images/ambulance.svg'
import facebook from '@/public/footer-images/socialicon-facebook.svg'
import linkedin from '@/public/footer-images/socialicon-linkedin.svg'
import instagram from '@/public/footer-images/socialicon-instagram.svg'
import twitter from '@/public/footer-images/socialicon-twitter.svg'
import youtube from '@/public/footer-images/socialicon-youtube.svg'
import CustomLink from './custom-link'
import { useRouter } from 'next/navigation'


const footerData = [
    {
        icon: mail,
        text: 'care@apexhospitals.com',
        img: '/images/facebook.svg',
        link: 'mailto:care@apexhospitals.com'
    },
    {
        icon: informationbox,
        text: '+91 9829030011',
        img: '/images/facebook.svg',
        link: 'tel:+919829030011'
    },
    {
        icon: emergency,
        text: '0141-4101111',
        img: '/images/facebook.svg',
        link: 'tel:01414101111'
    },
    {
        icon: ambulance,
        text: '+91 9929380108',
        img: '/images/facebook.svg',
        link: 'tel:+919929380108'
    }
]





const icons = [
    {
        image: facebook,
        link: 'https://www.facebook.com/ApexHospitals?mibextid=ZbWKwL'
    },
    {
        image: linkedin,
        link: 'https://www.linkedin.com/company/apexhospitals/'
    },
    {
        image: instagram,
        link: 'https://www.instagram.com/apex.hospitals/?igshid=MzRlODBiNWFlZA%3D%3D'
    },
    {
        image: twitter,
        link: 'https://twitter.com/apex_hospitals?t=6UYoRFkDG1JTsFP3ogdDuw&s=08'
    },
    {
        image: youtube,
        link: 'https://www.youtube.com/@apexhospitals6691'
    },
]

 function Footer({lang, navigation}) {
    // const locations = await getlocation();
    const [locations, setLocations] = useState();

    const router = useRouter();
    const handleButtonClick = () => {
        if (typeof window !== 'undefined') {
          const parentURL = window.location.origin;
    
          if (lang === 'en') {
            // Use router.push for client-side navigation to parentURL
            router.push(parentURL);
          } else {
            // Use router.push for client-side navigation to '/hi'
            router.push(`/${lang}`);
          }
        }
      };

const apexCofE = [
    {
        title: navigation.cardiology,
        link: '/centre-of-excellence/cardiology'
    }, {
        title: navigation.cardioThoracicSurgery,
        link: '/centre-of-excellence/cardiothoracic-surgery'
    }, {
        title: navigation.gastroenterology,
        link: '/centre-of-excellence/gastroenterology'
    }, {
        title: navigation.generalSurgery,
        link: '/centre-of-excellence/general-surgery'
    }, {
        title: navigation.haematoOncology,
        link: '/centre-of-excellence/haemato-oncology'
    }, {
        title: navigation.icuAndCriticalCare,
        link: '/centre-of-excellence/icu-and-critical-care'
    }, {
        title: navigation.internalMedicine,
        link: '/centre-of-excellence/internal-medicine'
    }, {
        title: navigation.nephrology,
        link: '/centre-of-excellence/nephrology'
    }, {
        title: navigation.neurology,
        link: '/centre-of-excellence/neurology'
    }, {
        title: navigation.neurosurgery,
        link: '/centre-of-excellence/neurosurgery'
    }, {
        title: navigation.oncology,
        link: '/centre-of-excellence/oncology'
    }, {
        title: navigation.orthopaedics,
        link: '/centre-of-excellence/orthopaedics'
    }, {
        title: navigation.urology,
        link: '/centre-of-excellence/urology'
    }, {
        title: navigation.vascularSurgery,
        link: '/centre-of-excellence/vascular-surgery'
    },
]

const quickLinks = [
    {
        title: navigation.aboutUs,
        link: '/about-us'
    },
    {
        title: navigation.contactUs,
        link: '/contact-us'
    },
    {
        title: navigation.academics,
        link: '/academics'
    },
    {
        title: navigation.careers,
        link: '/careers'
    },
    {
        title: navigation.doctors,
        link: '/doctors-list'
    },
    {
        title: navigation.MedicalTourism,
        link: '/medical-tourism'
    },
    {
        title: navigation.getEstimate,
        link: '/get-estimate'
    },
    {
        title: navigation.blog,
        link: '/blogs-articles'
    },
    {
        title: navigation.news,
        link: '/news'
    },
    {
        title: navigation.events,
        link: '/events'
    },
    (lang==='en' && {
        title: navigation.symptoms,
        link: '/symptoms'
    }),
    {
        title: navigation.healthCheckup,
        link: '/health-checkup'
    }
    // {
    //     title: 'FAQs',
    //     link: '/doctors#sec_faqs'
    // },
]
     // Function to fetch data from an API using async/await
     async function fetchDataFromApi(url) {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          return data;
        } catch (error) {
          throw error;
        }
      }
      
    useEffect(() => {
        const apiUrlforlocation =  `${process.env.NEXT_PUBLIC_API_URL}/locations?locale=${lang?lang:'EN'}` ;
        (async () => {
            try {
              const data = await fetchDataFromApi(apiUrlforlocation);
            //   console.log(data);
              setLocations(data);
            } catch (error) {
              console.error("Error:", error);
            }
          })();
      }, []);
    return (
        <>
        <div className='custom-container my-8 pb-16 lg:pb-6 bg-primaryBlack rounded-3xl'>
            {/* <div className=''> */}
                <div className='flex flex-col md:flex-row justify-between'>
                    <div className='pb-8 pt-5 px-5 md:w-1/2 lg:w-1/4'>
                       <span onClick={handleButtonClick}>
                            <Image src={apexlogo} className='relative object-contain object-center w-[260px] sm:w-[220px] h-auto' width={267} height={65} alt='footer logo' title='footer logo' />
                        </span>
                        <FooterDetails footerData={footerData} />
                        <div className='mt-5'>
                            <h3 className='text-greyscaleGrey md:text-base text-2xl font-thin md:font-bold mb-2 '>{navigation.download}</h3>
                            <div className='mt-2 flex gap-2 sm:items-center items-start justify-center sm:justify-start md:flex-row flex-col'>
                                <Link href='https://apps.apple.com/in/app/apex-hospitals/id1566699843?utm_source=App+dnw&utm_medium=gmb+2&utm_campaign=GMB' target='_blank' className='apex_footerappstore'>
                                   <Image src={appstore} alt='app store' title='app store' className='relative object-contain object-center w-[120px] h-full hover:scale-105 transition-all ease-in-out duration-500' width={120} height={40} />
                                </Link>
                                <Link href='https://play.google.com/store/apps/details?id=com.apexhospital&utm_source=APP+dnw&utm_medium=gmb&utm_campaign=gmb2' target='_blank' className='apex_footerplaystore'>
                                   <Image src={playstore} alt='play store' title='play store' className='relative object-contain object-center w-[120px] h-full hover:scale-105 transition-all ease-in-out duration-500' width={120} height={40} />
                                </Link>
                            </div>
                        </div>
                        <FooterIcons icons={icons} />
                    </div>
                    <div className='mt-2 md:mt-6 px-5 md:w-1/2 lg:w-1/4'>
                        <h3 className='text-greyscaleGrey md:text-base text-2xl font-thin md:font-bold mb-2'>{navigation.locations}</h3>
                        {locations && locations.data?(locations.data.map((e,i)=>(
                           
                                <div className='leading-8' key={i}>
                                    <CustomLink lang={lang} href={`/hospitals/${e.attributes.locationTag.toLowerCase().replaceAll(/\s/g, '-')}`} className='text-greyscaleWhite text-lg md:text-sm hover:pl-1 duration-500 transition-all ease-in-out hover:text-[#adadad] footer_locationlinks'>
                                    {e.attributes.locationCity.toUpperCase().replace('(RAJASTHAN)','')}
                                    </CustomLink>
                                  
                                </div>))):(<p>Loading...</p> 
                                                                )} 
                    </div>
                    <div className='mt-6 px-5 md:w-1/2 lg:w-1/4'>
                        <h3 className='text-greyscaleGrey md:text-base text-2xl font-thin md:font-bold mb-2'>{navigation.coe}</h3>
                        {
                            apexCofE?.map((item, i) => (
                                <div key={i} className='leading-8 '>
                                    <CustomLink lang={lang} href={item.link} className='text-greyscaleWhite text-lg md:text-sm uppercase  hover:pl-1 duration-500 transition-all ease-in-out hover:text-[#adadad] footer_specialitylinks'>
                                        {item?.title}
                                    </CustomLink>

                                </div>
                            ))
                        }
                    </div>
                    <div className='mt-6 px-5 md:w-1/2 lg:w-1/4'>
                        <h3 className='text-greyscaleGrey md:text-base text-2xl font-thin md:font-bold mb-2'>{navigation.quickLink}</h3>
                        {
                            quickLinks?.map((ele, i) => (
                                <div key={i} className='leading-8'>
                                    <CustomLink lang={lang} href={ele?.link} className=' text-greyscaleWhite text-lg md:text-sm uppercase hover:pl-1 duration-500 transition-all ease-in-out hover:text-[#adadad] footer_quicklinks'>
                                        {ele?.title}
                                    </CustomLink>

                                </div>
                            ))
                        }
                    </div>
                </div>
                
            {/* </div> */}

            <div className='flex text-gray-100 gap-3 flex-row justify-center mt-2 flex-wrap sm:flex-nowrap '>
                <div className="flex items-center">
                    <CustomLink lang={lang} href='/terms-condition' className='footer_termslink'>
                        <span className="text-greyscaleWhite pr-2 md:pr-3 text-sm duration-500 transition-all ease-in-out hover:text-[#adadad]">{navigation.terms}</span>
                    </CustomLink>
                    <div className="border-r border-gray-100 h-4"></div>
                </div>
                <div className="flex items-center">
                    <CustomLink lang={lang} href='/privacy-policy' className='footer_privacylink'>
                        <span className="text-greyscaleWhite pr-2 md:pr-3 text-sm  duration-500 transition-all ease-in-out hover:text-[#adadad]">{navigation.privacy}</span>
                    </CustomLink>
                    <div className="border-r border-gray-100 h-4"></div>
                </div>
                <div className="flex items-center">
                    <CustomLink lang={lang} href='/sitemap' className='footer_sitemaplink'>
                        <span className="text-greyscaleWhite md:pr-4 text-sm  duration-500 transition-all ease-in-out hover:text-[#adadad]">{navigation.siteMap}</span>
                    </CustomLink>
                </div>

            </div>
        </div>
        {/* <Chatbot/> */}
        <Whatsapp navigation={navigation} lang={lang}/>
        </>

    )
}

export default Footer