import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

function FooterIcons({ icons }) {
    return (
        <div className='mt-5 flex gap-3 justify-start items-center'>
            {icons?.map((icon, i) => (
            <Link href={icon.link} className='bg-white block sm:bg-primaryBlack w-fit social-medialinks' key={i} target='_blank' >
                <Image src={icon.image} className='relative w-10 bg-primaryBlack h-auto max-w-[40px] object-contain object-center hover:scale-110 transition-all ease-in-out duration-500' width={32} height={32} alt='footer-icons' />
            </Link>
            ))}
        </div>
    )
}

export default FooterIcons