'use client'
import React, { useEffect, useState } from 'react';
import Link from "next/link";
import Image from "next/image";
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation'; 
import { Button } from "../ui/button";
import Loading from "@/components/loading"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import Headercomponent from './header-component';
import landLine from '@/public/images/entypo_landline.svg';
import call from '@/public/images/call-logo.svg';
import emergency from '@/public/images/emergency-logo.svg';
import apexlogo from '@/public/images/apex_logo_head.svg';
import dropDownImg from '@/public/images/material-symbols_arrow-drop-down-rounded.svg';
import hamburgerImg from '@/public/images/hamburger-logo.svg';
import homeImg from '@/public/home-images/Group.svg';
import img from '@/public/images/touch-icon.svg';
import xcircle from '@/public/home-images/x-circle.svg';
import { i18n } from '@/i18n.config';
import CustomLink from './custom-link';




 function Header({lang, navigation}) {
   
    // console.log(navigation);
    const router = useRouter();
    const pathname = usePathname();
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [navOpen,setNavOpen] = useState(false); //header symbol hamburger and close
    const [dropDown,setDropDown]=useState(true);
    const [book , setBook] = useState(false);
    const [fetchedData, setFetchedData] = useState(); // Initialize with null or an appropriate initial value
    const [coeData, setCoeData] = useState();
    const [locationData,setLocationData] = useState();
    const [normalAccordionOpen, setNormalAccordionOpen] = useState(false);
    
    //for internationalization
   
    const redirectedPathName = (locale) => {
        if (!pathname) return '/'
    
        const pathnameIsMissingLocale = i18n.locales.every(
          locale => !pathname.startsWith(`/${locale}/`) && pathname !== `/${locale}`
        )
    
        if (pathnameIsMissingLocale) {
          if (locale === i18n.defaultLocale) return pathname
          return `/${locale}${pathname}`
        } else {
          if (locale === i18n.defaultLocale) {
            const segments = pathname.split('/')
            const isHome = segments.length === 2
            if (isHome) return '/'
    
            segments.splice(1, 1)
            return segments.join('/')
          }
    
          const segments = pathname.split('/')
          segments[1] = locale
          return segments.join('/')
        }
      }
    

    // Function to fetch data from an API using async/await
    async function fetchDataFromApi(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        throw error;
      }
    }
  
    useEffect(() => {
      const apiUrlforSpeciality =
      `${process.env.NEXT_PUBLIC_API_URL}/specialities?filters[COE][$eq]=false&locale=${lang?lang:'EN'}`;
  
      (async () => {
        try {
          const data = await fetchDataFromApi(apiUrlforSpeciality);
          setFetchedData(data);
        } catch (error) {
          console.error("Error:", error);
        }
      })();

      const apiUrlforCoe = `${process.env.NEXT_PUBLIC_API_URL}/specialities?filters[COE][$eq]=true&locale=${lang?lang:'EN'}`;

      (async () => {
        try {
          const data = await fetchDataFromApi(apiUrlforCoe);
          setCoeData(data);
        } catch (error) {
          console.error("Error:", error);
        }
      })()
      const apiUrlforLocation = `${process.env.NEXT_PUBLIC_API_URL}/locations?locale=${lang?lang:'EN'}`;

      (async () => {
        try {
          const data = await fetchDataFromApi(apiUrlforLocation);
          setLocationData(data);
        } catch (error) {
          console.error("Error:", error);
        }
      })()

  
    
    }, []); // Empty dependency array ensures this effect runs only once on mount
  
   

 

   const handlechange =()=>{
    setDropDown(true);
    setNavOpen(!navOpen)
    setIsOpen(false)
   }

   const bookChange= ()=>{
    setBook(!book);
   }
    const [whiteOverlay, setWhiteOverlay] = useState(true); //added this to fix animation start delay
    
    var introAnimationShown;
    if(typeof window !== 'undefined'){
        introAnimationShown = localStorage.getItem('introAnimation') === 'shown';
    }
    
    useEffect(()=>{
        // const introAnimationShown = localStorage.getItem('introAnimation') === 'shown';
        setShowLoading(!introAnimationShown);
        setTimeout(()=>{
            setShowLoading(false)
            localStorage.setItem('introAnimation', 'shown')
        }, 3000)
        setTimeout(()=>{
            setWhiteOverlay(false)
        }, 200)
    }, []) // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 40) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount
    // const ''=()=>{
    //     console.log('open');
    // }

    const handleCloseDropDown = ()=>{
        setDropDown(false)
        setNavOpen(true)
        // console.log('onclick');
    }

    const handleclick = () =>{
        router.replace('/');
    }

      
    const handleButtonClick = () => {
        if (typeof window !== 'undefined') {
          const parentURL = window.location.origin;
    
          if (lang === 'en') {
            // Use router.push for client-side navigation to parentURL
            router.push(parentURL);
          } else {
            // Use router.push for client-side navigation to '/hi'
            router.push(`/${lang}`);
          }
        }
      };
   
    const home = isScrolled ?'left-1/2 transform -translate-x-1/2 lg:fixed xl:w-[1170px] lg:mt-3 lg:justify-between lg:top-0 border fixed rounded-full border-[#ccc] z-50 bg-primaryWhite flex sm:flex-wrap justify-between top-3 items-center p-2 mx-0 w-11/12 py-1 lg:pr-3 lg:pl-4 mt-0 transition-all duration-1000 header-menu-bar' :
      'lg:border-none z-50 lg:top-0 bg-primaryWhite lg:w-full lg:relative  fixed flex sm:flex-wrap justify-between top-3 items-center p-2 mx-0 w-11/12 py-1 lg:pr-3 lg:pl-4 border border-[#ccc] mt-0 rounded-full transition-all duration-1000 left-1/2 transform -translate-x-1/2' ;

    return (
        <header className='sticky top-0 bg-primaryWhite z-50 min-h-[82px] mb-8'>
           {( pathname==='/' || pathname ==='/en' || pathname === '/hi') && <nav>
                <div className={isScrolled ? "lg:flex items-center justify-end opacity-0 transition-opacity hidden duration-500" : "hidden transition-opacity duration-800 lg:flex items-center justify-end max-w-full pl-0 pr-0 py-0  mx-auto"}>
                    <ul className="flex flex-row md:text-base text-sm font-medium space-x-3 pr-0 pl-2 py-2">
                        <li className="border border-primaryGreen rounded-full flex items-center cursor-pointer">
                            <a href="tel:+919829030011" className='home_landline flex items-center px-4 py-2 '>
                                <Image src={landLine} alt="landline logo" title="landline logo" width={20} height={20} className='w-4'/>
                                <p className="header-font-color px-2 landline-no uppercase" >{navigation.patientHelpline}</p>
                            </a>
                        </li>
                        <li className="border border-primaryGreen rounded-full flex items-center px-4 py-2 cursor-pointer">
                            <Image src={call} alt="Landline-logo" width={20} height={20} className='w-4'/>
                            <CustomLink lang={lang} href='/contact-us' className='home_contactustop'>
                                <p className="header-font-color px-2 contact-us uppercase" >{navigation.contactUs}</p>
                            </CustomLink>
                        </li>
                        <li className="border border-primaryRed rounded-full flex items-center  cursor-pointer">
                                <a href="tel:01414101111" className='home_emergency flex items-center px-4 py-2'>
                                <Image src={emergency} alt="Landline-logo" width={20} height={20} className='w-4'/>
                                <p className="header-font-color px-2 emergency uppercase" aria-current="page" >{navigation.emergency}</p>
                                </a>
                        </li>
                        <li className="border border-primaryGrey border-r-0 rounded-r-lg rounded-full flex items-center pl-4 pr-0 py-2 bg-primaryGrey cursor-pointer">
                            <p className="text-white-200 px-2 text-primaryWhite "><span onClick={handleclick} className='cursor-pointer'> ENGLISH </span> <span>|</span><Link className=' mx-4 cursor-pointer' href={redirectedPathName(i18n.locales[1])}>हिन्दी</Link></p>
                        </li>
                    </ul>
                </div>
            </nav>}
            <nav>
                 <div className={( pathname==='/' || pathname ==='/en' || pathname === '/hi') ?home:'left-1/2 -translate-x-1/2 lg:fixed xl:w-[1170px] lg:mt-2 lg:justify-between lg:top-0 border fixed rounded-full border-[#ccc] z-50 bg-primaryWhite flex sm:flex-wrap justify-between top-3 items-center p-2 mx-0 w-11/12 py-1 lg:pr-3 lg:pl-4 mt-0 header-menu-bar'} >
                       
                        <div onClick={handleButtonClick} className="flex items-center apex_homelogo cursor-pointer">
                            <Image src={apexlogo} width={256} height={64} alt="apex-logo" title='apex-logo' loading='eager' priority className={isScrolled || pathname!=='/' ? 'md:w-52 md:h-12 h-10 w-40 ': 'h-10 w-40 md:w-52 md:h-12 lg:w-64 lg:h-16'} />
                        </div>
                       
                        <div className="flex items-center justify-end  ">
                            <ul className="flex flex-row font-medium space-x-4 md:text-base text-sm pl-2 pr-0 py-1">
                                <li className="hidden lg:flex items-center px-3 py-2 cursor-pointer">
                                    <Headercomponent lang={lang} navigation={navigation}/>
                                </li>
                                <li className="hidden lg:flex items-center px-3 py-2 cursor-pointer">
                                    <DropdownMenu>
                                        <DropdownMenuTrigger className="flex items-center header-font-color outline-none uppercase" >{navigation.locations}<Image src={dropDownImg} width={24} height={24} alt="Logo" /></DropdownMenuTrigger>
                                        <DropdownMenuContent className="rounded-2xl text-primaryGrey relative top-2 right-16 shadow-lg p-5 m-4">
                                           {locationData && locationData.data?(locationData.data.map((e,i)=>( <CustomLink lang={lang} href={`/hospitals/${e.attributes.locationTag.toLowerCase().replaceAll(/\s/g, '-')}`} key={i} className='location_dropdownhomelinks'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer mb-3">{e.attributes.locationCity.toUpperCase()}</DropdownMenuItem></CustomLink>))):(
                                                                <p>Loading...</p> 
                                                                )}
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </li>

                                
                                    <li className="hidden lg:flex items-center h-12 px-5 py-2 cursor-pointer border-primaryWhite bg-primaryBlue rounded-full">
                                    <CustomLink lang={lang} href={'/doctors-list'} legacyBehavior>
                                        <div className="text-primaryWhite find-doctor uppercase menu_dropdownhomelinks">{navigation.findaDoctor}</div>
                                     </CustomLink>
                                </li>

                                
                                <li>
                                    <div className='hidden lg:block relative'>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger className="text-primaryGrey outline-none flex items-center justify-center cursor-pointer w-12 h-12 border header-border-color rounded-full" >
                                                <Image src={hamburgerImg} alt="hamburger-logo" loading='eager' priority width={21} height={20} />
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="rounded-2xl text-primaryGrey relative top-0 right-16 shadow-lg p-5 py-3 m-4">
                                                <CustomLink lang={lang} href={'/doctors-list'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.doctors}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/about-us'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.aboutUs}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/academics'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.academics}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/careers'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.careers}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/blogs-articles'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.blog}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/news'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.news}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/events'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.events}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/health-checkup'} ><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.healthCheckup}</DropdownMenuItem></CustomLink>
                                                <CustomLink lang={lang} href={'/eohs-policy'} ><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.EOHSPolicy}</DropdownMenuItem></CustomLink>
                                               {lang==='en' && <CustomLink lang={lang} href={'/symptoms'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.symptoms}</DropdownMenuItem></CustomLink>}
                                                <CustomLink lang={lang} href={'/contact-us'}><DropdownMenuItem className=" focus:bg-transparent text-primaryGrey focus:text-primaryGreyText hover:text-primaryGreyText mb-1 cursor-pointer menu_dropdownhomelinks uppercase">{navigation.contactus}</DropdownMenuItem></CustomLink>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>

                                    <div className='block lg:hidden'>
                                        <DropdownMenu  onOpenChange={handlechange} >
                                            <DropdownMenuTrigger className="text-primaryGrey outline-none relative flex items-center justify-center cursor-pointer w-12 h-12 border header-border-color rounded-full" type="button" aria-label="hamburger">
                                             {navOpen?<Image src={homeImg} height={18} width={18} alt='close'/>: <Image src={hamburgerImg} alt="open" priority loading='eager' width={21} height={20} />}
                                            </DropdownMenuTrigger>
                                            <div className='relative '>
                                            <DropdownMenuContent className={`${dropDown? 'block' : 'hidden'} relative rounded-2xl text-primaryGrey top-4 left-0 p-2 w-screen`}>
                                                {/* <li className="border border-primaryGrey border-r-0 rounded-full flex items-center px-4 py-2 bg-primaryGrey cursor-pointer justify-center text-primaryWhite"> हीं/A </li> */}
                                                <li className='flex justify-between flex-wrap gap-y-2 align-middle pb-1' >
                                                    <a href="tel:+919829030011" className='home_landline' >
                                                        <DropdownMenuItem className="p-0">
                                                            <div className='border-2 border-primaryGreen rounded-2xl p-2 mr-1 w-24 h-16 cursor-pointer'>
                                                                <Image src={landLine} alt="landline logo" width={20} height={20} className='m-auto pb-1' priority loading='eager'/>
                                                                <p className='text-xs font-medium text-center landline-no uppercase'>{navigation.patientHelpline}</p>
                                                            </div>
                                                        </DropdownMenuItem>
                                                    </a>
                                                    <CustomLink lang={lang} href='/contact-us' className='home_contactustop'>
                                                        <DropdownMenuItem  className="p-0">
                                                            <div className='border-2 border-primaryGreen rounded-2xl p-2 mr-1 w-24 h-16 cursor-pointer'>
                                                                <Image src={call} alt="Landline-log" width={20} height={20} className='m-auto pb-1' priority loading='eager'/>
                                                                <p className='text-xs font-medium text-center whitespace-nowrap contact-us'>{navigation.contactUs}</p>
                                                            </div>
                                                        </DropdownMenuItem>
                                                    </CustomLink>
                                                    <a href="tel:01414101111" className='home_emergency'>
                                                        <DropdownMenuItem  className="p-0">
                                                            <div className='border-2 border-primaryRed rounded-2xl p-2 w-24 h-16 cursor-pointer' >
                                                                <Image src={emergency} alt="landline logo" width={20} height={20} className='m-auto pb-1' priority loading='eager'/>
                                                                <p className='text-xs font-medium text-center emergency'>{navigation.emergency}</p>
                                                            </div>
                                                        </DropdownMenuItem>
                                                    </a>
                                                    {( pathname==='/' || pathname ==='/en' || pathname === '/hi') &&
                                                        <div className="border w-full border-primaryGrey rounded-full flex items-center justify-center px-4 py-2 bg-primaryGrey cursor-pointer">
                                                        
                                                            <p className="text-white-200 px-2 text-primaryWhite "><span onClick={handleclick} className='cursor-pointer'> ENGLISH </span> <span>|</span><Link className=' mx-4 cursor-pointer' href={redirectedPathName(i18n.locales[1])}>हिन्दी</Link></p>
                                                        </div>
                                                    }
                                                </li>
                                                <div className=" overflow-y-scroll h-80  overflow-x-hidden">
                                                    <Accordion type="single" collapsible className="w-full" onValueChange={newValue => {
                                                        // console.log(newValue);
                                                                setNormalAccordionOpen(newValue === 'specialities' || newValue === 'locations' || newValue === 'apex');
                                                                // Close the custom accordion when normal accordion opens
                                                                setIsOpen(false);
                                                            }}>

                                                        <AccordionItem value="specialities">
                                                            <AccordionTrigger img={true} className="hover:no-underline pt-4 pb-0 text-black text-lg sm:text-xl font-light">{navigation.specialities}</AccordionTrigger>
                                                            
                                                            <div className="relative max-h-48 p-0 overflow-scroll">
                                                                <AccordionContent className='text-sm md:text-base font-semibold text-primaryGrey pb-2 pt-2'>{navigation.coe}
                                                                </AccordionContent>
                                                                {coeData && coeData.data ? (
                                                                coeData.data.map((e, i) => (
                                                                <CustomLink lang={lang} key={i} href={`/centre-of-excellence/${e.attributes.specialityTag.toLowerCase().replaceAll(/\s/g, '-')}`} onClick={handleCloseDropDown} className='speciality_dropdownhomelinks'> 
                                                                  <AccordionContent className="p-0">  <DropdownMenuItem className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer' >{e.attributes.specialityName.toUpperCase()}
                                                                  </DropdownMenuItem>  </AccordionContent>
                                                                </CustomLink>
                                                                ))
                                                                ) : (
                                                                <p>Loading...</p> // You can display a loading message or any other UI while data is being fetched
                                                                )}
                                                                <AccordionContent className=' text-sm md:text-base font-semibold text-primaryGrey'>{navigation.navotherSpecialities}</AccordionContent>
                                                                {fetchedData && fetchedData.data ? (
                                                                fetchedData.data.map((e, i) => (
                                                                <CustomLink lang={lang} key={i} href={`/speciality/${e.attributes.specialityTag.toLowerCase().replaceAll(/\s/g, '-')}`} onClick={handleCloseDropDown} className='speciality_dropdownhomelinks'> 
                                                                   <AccordionContent><DropdownMenuItem className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer' >{e.attributes.specialityName.toUpperCase()}</DropdownMenuItem></AccordionContent>
                                                                </CustomLink>
                                                                ))
                                                                
                                                                ) : (
                                                                <p>Loading...</p> // You can display a loading message or any other UI while data is being fetched
                                                                )}
                                                            </div>
                                                        </AccordionItem>

                                                        <AccordionItem value="locations">

                                                            <AccordionTrigger img={true} className="hover:no-underline py-3 text-black font-light text-lg sm:text-xl">{navigation.locations}</AccordionTrigger>

                                                            {locationData && locationData.data?(locationData.data.map((e,i)=>(<CustomLink lang={lang} href={`/hospitals/${e.attributes.locationTag.toLowerCase().replaceAll(/\s/g, '-')}`} key={i} onClick={handleCloseDropDown} className='location_dropdownhomelinks'>
                                                            <AccordionContent>
                                                                 <DropdownMenuItem className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer' >{e.attributes.locationCity.toUpperCase()}</DropdownMenuItem> </AccordionContent>
                                                            </CustomLink>))):(
                                                                <p>Loading...</p> 
                                                                )}
                                                            
                                                        </AccordionItem>

                                                        <AccordionItem value="apex">
                                                            <AccordionTrigger img={true} className="hover:no-underline font-light text-lg sm:text-xl py-3 text-black">{navigation.apex}</AccordionTrigger>
                                                            <CustomLink lang={lang} href='/doctors-list' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.doctors}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/about-us' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.aboutUs}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/academics' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.academics}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/careers' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.careers}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/blogs-articles' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.blog}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/news' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.news}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/events' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.events}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/health-checkup' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.healthCheckup}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            <CustomLink lang={lang} href='/eohs-policy' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.EOHSPolicy}</DropdownMenuItem></AccordionContent></CustomLink>
                                                            {lang === 'en' && <CustomLink lang={lang} href='/symptoms' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.symptoms}</DropdownMenuItem></AccordionContent></CustomLink>}
                                                            <CustomLink lang={lang} href='/contact-us' ><AccordionContent className=' text-sm md:text-base text-primaryGrey hover:text-primaryGreyText uppercase pl-2 pt-0 pb-1 cursor-pointer menu_dropdownhomelinks'><DropdownMenuItem className="p-0">{navigation.contactus}</DropdownMenuItem></AccordionContent></CustomLink>
                                                        </AccordionItem>
                                                    </Accordion>
                                                    {isOpen && <div className='my-2'>
                                                       <CustomLink lang={lang} href='/book/book-an-appointment'><DropdownMenuItem><p className='pb-1 pt-0 uppercase pl-2 text-sm book_dropdownhomelinks'>{navigation.bookanappointment}</p></DropdownMenuItem></CustomLink> 
                                                       <CustomLink lang={lang} href='/health-checkup'><DropdownMenuItem><p className='pb-1 pt-0 uppercase pl-2 text-sm book_dropdownhomelinks'>{navigation.HealthCheckup}</p></DropdownMenuItem></CustomLink> 
                                                       <CustomLink lang={lang} href='/eohs-policy'><DropdownMenuItem><p className='pb-1 pt-0 uppercase pl-2 text-sm book_dropdownhomelinks'>{navigation.EOHSPolicy}</p></DropdownMenuItem></CustomLink> 
                                                       <CustomLink lang={lang} href='/application-download'><DropdownMenuItem><p className='pb-1 pt-0 uppercase pl-2 text-sm book_dropdownhomelinks'>{navigation.Downloadapp}</p></DropdownMenuItem></CustomLink> 
                                                       <CustomLink lang={lang} href='/medical-tourism'><DropdownMenuItem><p className='pb-1 pt-0 uppercase pl-2 text-sm book_dropdownhomelinks'>{navigation.MedicalTourism}</p></DropdownMenuItem></CustomLink> 
                                                       <CustomLink lang={lang} href='/get-estimate'><DropdownMenuItem><p className='pb-1 pt-0 uppercase pl-2 text-sm book_dropdownhomelinks'>{navigation.getEstimate}</p></DropdownMenuItem></CustomLink> 
                                                    </div>}

                                                </div>
                                                <div className="flex justify-between bottom-4">
                                                    <Button className='bg-primaryYellow hover:bg-primaryYellow rounded-full align-middle px-16 w-full h-11 book_dropdownhome' onClick={() => { setIsOpen(!isOpen); setNormalAccordionOpen(false);}}>
                                                        <Image src={img} alt="touch icon" title="touch icon" width={23} height={23} className="mr-3" priority loading='eager'/>{isOpen ? navigation.close : navigation.book}</Button>
                                                </div>
                                            </DropdownMenuContent>
                                            </div>
                                        </DropdownMenu>
                                    </div>
                                </li>
                            </ul>
                        </div>
                 </div>
                    {/* Book */}
                <div className={isScrolled || (pathname!=='/' && pathname !== '/en' && pathname !== '/hi') ?`hidden lg:flex lg:top-20 lg:fixed xl:top-1 transition-all duration-1000 transform pt-1 xl:pt-2 absolute right-0 z-50 menu-bookbutton `:'hidden lg:flex lg:fixed absolute right-0 z-20 top-44' } >
                    <DropdownMenu onOpenChange={bookChange}>
                        <DropdownMenuTrigger className="border border-primaryYellow border-r-0 rounded-r-lg rounded-full flex items-center xl:p-4 px-2 bg-primaryYellow w-auto xl:w-28 cursor-pointer h-16 text-white outline-none book_dropdownhome" >
                            {book?<><Image src={xcircle} width={24} height={24} alt="Flowbite Logo" className='m-auto'priority loading='eager'/>{navigation.close}</>: (<><Image src={img} width={24} height={24} alt="Logo" className='m-auto uppercase' priority loading='eager'/>{navigation.book}</>)}
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="rounded-2xl text-gray-600 relative top-0 shadow-lg p-2.5 m-2">
                            <CustomLink lang={lang} href='/book/book-an-appointment'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey uppercase focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer book_dropdownhomelinks">{navigation.bookanappointment}</DropdownMenuItem></CustomLink>
                            <CustomLink lang={lang} href='/video-consultation'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey uppercase focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer book_dropdownhomelinks">{navigation.videoconsult}</DropdownMenuItem></CustomLink>
                            <CustomLink lang={lang} href='/health-checkup'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey uppercase focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer book_dropdownhomelinks">{navigation.HealthCheckup}</DropdownMenuItem></CustomLink>
                            <CustomLink lang={lang} href='/eohs-policy'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey uppercase focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer book_dropdownhomelinks">{navigation.EOHSPolicy}</DropdownMenuItem></CustomLink>
                            <CustomLink lang={lang} href='/application-download'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey uppercase focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer book_dropdownhomelinks">{navigation.Downloadapp}</DropdownMenuItem></CustomLink>
                            <CustomLink lang={lang} href='/medical-tourism'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey uppercase focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer book_dropdownhomelinks">{navigation.MedicalTourism}</DropdownMenuItem></CustomLink>
                            <CustomLink lang={lang} href='/get-estimate'><DropdownMenuItem className="focus:bg-transparent text-primaryGrey uppercase focus:text-primaryGreyText hover:text-primaryGreyText cursor-pointer book_dropdownhomelinks">{navigation.getEstimate}</DropdownMenuItem></CustomLink>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                
            </nav>
            {showLoading&&!introAnimationShown&&<Loading />}
            {whiteOverlay&&
             <div className='h-screen w-screen fixed bg-white top-0 left-0 z-50'>
             </div>}
        </header>
    )
}

export default Header;


  

