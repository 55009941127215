
import { Button } from "../ui/button";
import Image from "next/image";
import imghome from '@/public/home-appointment-images/phone.svg'
import CustomLink from "./custom-link";

function Whatsapp ({lang, navigation}){
    return(
    <div className="fixed bottom-0 left-0 z-50 w-full flex p-2 bg-primaryWhite md:hidden">
        <CustomLink lang={lang} href='/book/book-an-appointment' className="grow mr-2">
            <Button type="submit" variant="bluebutton" className="capitalize w-full px-3 h-12 floating_bookbtn">{navigation.bookanappointment}</Button>
        </CustomLink> 
       <div className="flex shrink-0">
        <a href="tel:+919829030011" className='w-12 h-12 shrink-0 flex items-center justify-center border-2 border-green-600 p-3 rounded-full bg-primaryWhite floating_callbtn'>
            <Image src={imghome} alt='phone' title='phone' height={24} width={24} />
        </a>
        {/* <a href="https://wa.me/9829030011?text=Hello%20there,%20I%20have%20a%20question" className='w-12 h-12 flex items-center justify-center shrink-0 border border-green-600 rounded-full bg-green-600 ml-2'>
            <Image src='/home-appointment-images/whatsapp.svg' alt='whatsapp' title='whatsapp' height={24} width={24} />
        </a> */}
        </div>
    </div>
    )
};

export default Whatsapp;