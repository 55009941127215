import Image from "next/image";
import logo from '@/public/animation/logo.svg';
import animationcircle from '@/public/animation/circle.svg';
import animationlogo from '@/public/animation/logo-text.svg';
// import animationtag from '@/public/animation/tag.svg'

function Loading (){
    return(
        <div className="fixed fadeAnimation h-screen w-screen bg-white z-50 top-0 flex items-center justify-center " >
            <div className="absolute z-[999]">
                <Image src={animationcircle} alt="logo-animation" className="zoomOutAndDisappear" width={999} height={999} loading='eager' priority />
            </div>
            <div className="absolute z-[998] logo-container scale-50 lg:scale-100">
                <div className="flex">
                    <Image src={logo} alt="logo-animation" className="zoomInAndAppear logo-move" width={128} height={128} loading='eager' priority  />
                    <div className="typing w-0 overflow-hidden flex items-center">
                        <div className="w-96 shrink-0">
                            <Image src={animationlogo} alt="logo-animation" width={400} height={40} className="w-full shrink-0" loading='eager' priority />
                        </div>
                    </div>
                </div>
                <div className="w-0 showDummyDiv"></div>
                <div className=" flex items-center justify-center">
                    <div className="showTag w-0 h-0 overflow-hidden ">
                        <p className=" text-primaryGrey text-5xl leading-tight text-center font-normal">हम रिश्तों की उम्र बढ़ातें है !</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Loading;