import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

function FooterDetails({ footerData }) {
    return (
        <div>
            {
                footerData?.map((item, i) => (
                    <div key={i} className='mt-5 flex flex-row gap-1'>
                      
                            <Image src={item?.icon} width={21} height={20} alt='email' />
                        <a href={item.link} className='leading-8 text-greyscaleGrey text-lg md:text-sm footer_calllinks'>{item?.text}</a>
                        

                    </div>
                ))
            }
        </div>
    )
}

export default FooterDetails